<template>
  <div class="wrap search-result">
    <search-header />
    <div class="layout_body search_contain allContent_contain">
      <div class="all_header">
        <article class="swiper search_category">
          <swiper id="swiper-header" class="swiper-wrapper" :options="options">
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result?keyword=${keyword}`">
                {{ $t('Search.All') }}
                {{ allTotal.numberFormat() }}</router-link
              >
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide on">
              <router-link :to="`/search/result/product?keyword=${keyword}`">
                {{ $t('Search.Product') }} {{ productsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/brand?keyword=${keyword}`">
                {{ $t('Search.Brand') }} {{ brandsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/event?keyword=${keyword}`">
                {{ $t('Search.Event') }} {{ eventsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/coupon?keyword=${keyword}`">
                {{ $t('Search.Coupon') }} {{ couponsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
          </swiper>
        </article>
      </div>
      <!-- 필터 선택한 후 -->
      <filter-slider
        v-if="filterSlideItems.length > 0"
        :items="filterSlideItems"
        @click="handleClickTag"
      />
      <div
        :class="`${isLoading ? '' : 'off'}inner ptb14`"
        :style="filterSlideItems.length > 0 ? 'margin-top:54px;' : ''"
      >
        <span
          class="disblock"
          v-html="$t('Search.TotalResult', { Total: productsTotal })"
        >
        </span>
        <h3 class="new_sub_title">{{ $t('Search.Product') }}</h3>
        <article class="women_product item">
          <div class="prod_swiper noswiper_prod p0">
            <ul>
              <li v-for="item in items" :key="item.cit_id">
                <product-item :item="item" :bookMark="true" />
              </li>
            </ul>
          </div>
        </article>
        <infinite-loading
          v-if="hasMore"
          :identifier="infiniteId"
          style="opacity: 0"
          @infinite="loadDatas"
        />
        <div v-if="productsTotal === 0" class="empty_result_search">
          <p>{{ $t('Search.NoneSearch') }}</p>
        </div>
      </div>
      <div v-if="!isLoading" class="loading_wrap">
        <div class="loading_content">
          <img src="../../assets/images/design/loading.gif" alt="loading" />
        </div>
      </div>
    </div>
    <div>
      <!--      <div :class="`disflex btn_box filter-box slid_up ${isScroll} ? 'nav-up' : 'nav-down'`">-->
      <div class="disflex btn_box filter-box" :style="{ display: 'none' }">
        <button
          type="button"
          class="order_filter btn_lineblack btn_pop_open"
          @click="isShowSort = true"
        >
          {{ $t('Brand.OrderBy') }}
        </button>
        <button
          class="filter_filter btn_lineblack btn_pop_open"
          @click="handleClickFilter"
        >
          {{ $t('Item.Filter') }}
        </button>
      </div>

      <sild-footer class="items-sild-footer" />
    </div>
    <items-filter-popup
      v-if="isShowFilterPopup"
      v-model="filters"
      @close="isShowFilterPopup = false"
      @input="handleInputFilter"
    />
    <sort-popup
      v-if="isShowSort"
      :title="$t('Brand.OrderBy')"
      :items="sortItems"
      @close="isShowSort = false"
      @select="handleSelectedSort"
    />
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs } from 'vue-demi';
import SearchAPI from '../../apis/SearchAPI';
import InfiniteLoading from 'vue-infinite-loading';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import { toggleNav } from '../../utils/GlobalUtils';
import ItemsFilterPopup from '../items/components/ItemsFilterPopup.vue';
import filterItems from '@/consts/FilterItems';
import SortPopup from '../../components/popups/SortPopup.vue';
import FilterSlider from '../../components/ui/FilterSlider/FilterSlider.vue';
import useUserInfo from '../../composables/user/useUserInfo';
import SearchHeader from '../../components/layouts/components/headers/SearchHeader.vue';
import EventAPI from '../../apis/EventAPI';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
    InfiniteLoading,
    ProductItem,
    SildFooter,
    ItemsFilterPopup,
    SortPopup,
    FilterSlider,
    SearchHeader,
  },
  setup(_, context) {
    const { info } = useUserInfo();
    const query = context.root.$route.query;
    const keyword = query?.keyword;
    let isScroll = ref(false);
    // console.log("items context",context);
    const state = reactive({
      type: 'w',
      hasMore: true,
      infiniteId: Date.now(),
      items: [],
      isShowFilterPopup: false,
      filters: {},
      filterSlideItems: [],
      isShowSort: false,
      allTotal: 0,
      productsTotal: 0,
      brandsTotal: 0,
      eventsTotal: 0,
      couponsTotal: 0,
      isLoading: false,
    });

    const allTotal = computed(() => {
      return (
        state.productsTotal +
        state.brandsTotal +
        state.couponsTotal +
        state.eventsTotal
      );
    });

    const params = {
      page: 0,
      limit: 10,
      mem_id: info?.mem_id ?? 0,
    };

    let beforePosition = document.documentElement.scrollTop;
    let isScrolling;
    window.addEventListener('scroll', function () {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(function () {
        isScroll.value = false;
      }, 66);
    });
    window.addEventListener('scroll', function () {
      let afterPosition = document.documentElement.scrollTop;
      if (afterPosition > 50) {
        if (beforePosition < afterPosition) {
          // 스크롤 위로
          isScroll.value = true;
        } else {
          // 스크롤 아래로
          isScroll.value = false;
        }
      }
      beforePosition = afterPosition;
    });

    const createSearchParamValue = (key, value) => {
      switch (key) {
        case 'category':
          return value
            .filter((category) => category)
            .map((category) => category.key);

        case 'concept':
          return value.filter((concept) => concept);

        case 'age':
          return value.filter((age) => age);

        case 'color':
          return value.filter((color) => color);

        case 'style':
          return value.filter((style) => style);

        case 'price':
        case 'soldOut':
          return value;

        default:
          return '';
      }
    };

    const createSearchParams = () => {
      const filters = {
        ...params,
        keyword,
        type: state.type,
      };

      Object.keys(state.filters).forEach((key) => {
        const value = state.filters[key];

        filters[key] = createSearchParamValue(key, value);
      });
      return filters;
    };

    const loadDatas = async (attr) => {
      const search = createSearchParams();
      try {
        const { data } = await SearchAPI.searchItem(search);
        state.items = [...state.items, ...data.body.result.result_data];
        state.productsTotal = Number(data.body.result.count);
        if (data.body.result.result_data.length !== params.limit) {
          attr?.complete();
          state.hasMore = false;
        } else {
          params.page++;
          attr?.loaded();
        }
        state.isLoading = true;
      } catch (error) {
        console.log(error);
      }
    };

    let filterValues = null;

    (async () => {
      filterValues = await filterItems.getItemFilterOptions();
    })();

    const clearSearchParams = () => {
      params.page = 0;
      state.items = [];
      state.hasMore = true;
      state.infiniteId = Date.now();
    };

    const getFilterTags = (filters) => {
      const filterItems = [];

      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        switch (key) {
          case 'category':
          case 'concept':
          case 'age':
          case 'color':
          case 'style':
            value.forEach((checked, idx) => {
              if (checked) {
                filterItems.push({
                  path: `${key}/${idx}`,
                  label: filterValues[key][idx].label,
                });
              }
            });
            break;

          case 'price':
            value &&
              filterItems.push({
                path: key,
                label: `${value[0]}~${value[1]}`,
              });
            break;

          case 'soldOut':
            value &&
              filterItems.push({
                path: key,
                label: '품절 제외',
              });
            break;

          default:
            return '';
        }
      });

      return filterItems;
    };

    const loadAnotherSectionSearchCount = async () => {
      const { data: eventData } = await EventAPI.getEventList({
        offset: 0,
        order_by: 'start',
        mem_id: 1,
        is_option: '',
      });
      const { data } = await SearchAPI.searchKeyword(keyword);
      const [brandResult, couponResult] = data.body.result;

      state.brandsTotal = Number(brandResult.count);
      state.couponsTotal = Number(couponResult.count);
      state.eventsTotal = Number(eventData.total_rows);
    };

    loadAnotherSectionSearchCount();

    return {
      ...toRefs(state),
      allTotal,
      keyword,
      loadDatas,
      isScroll,
      sortItems,
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
      getMenuClassName(value) {
        const classes = ['menu', 'fontEn'];

        if (value === state.type) {
          classes.push('on');
        }

        return classes.join(' ');
      },
      handleClickFilter() {
        state.isShowFilterPopup = true;
      },
      handleInputFilter(filters) {
        state.filterSlideItems = getFilterTags(filters);

        state.isShowFilterPopup = false;

        clearSearchParams();
      },
      handleClickTag(item) {
        const paths = item.path.split('/');
        const filters = { ...state.filters };

        if (paths.length > 1) {
          filters[paths[0]][paths[1]] = false;
        }

        if (paths[0] === 'price') {
          filters['price'] = undefined;
        }

        if (paths[0] === 'soldOut') {
          filters['soldOut'] = false;
        }

        state.filters = filters;

        state.filterSlideItems = getFilterTags(state.filters);

        clearSearchParams();
      },
      handleSelectedSort(item) {
        state.isShowSort = false;
        params.orderBy = item.key;
        localStorage.setItem('item_sort_key', item.key);

        clearSearchParams();
      },
      handleClickTab(type) {
        state.type = type;
        clearSearchParams();
      },
    };
  },
  created() {
    window.addEventListener('scroll', toggleNav);
  },
  destroyed() {
    window.removeEventListener('scroll', toggleNav);
  },
};

const sortItems = [
  {
    key: '',
    label: '추천순',
  },
  {
    key: 'new_dtm',
    label: '신상품순',
  },
  {
    key: 'upd_dtm',
    label: '업데이트순',
  },
  {
    key: 'cit_sale_price_desc',
    label: '가격높은순',
  },
  {
    key: 'cit_sale_price_asc',
    label: '가격낮은순',
  },
  {
    key: 'sale',
    label: '할인율순',
  },
];
</script>

<style>
.btn_box.filter-box {
  bottom: 74px;
}
.btn_box.filter-box.nav-down {
  bottom: 74px;
}
.btn_box.filter-box.nav-up {
  bottom: -74px;
}
.filter-box.slid_up {
  top: auto !important;
  transition: bottom 0.2s ease-in-out !important;
}
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 999;
}
.loading_content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50em;
}
@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .loading_content .timesale_wrap {
    width: 50%;
  }
}
.off {
  opacity: 0;
}
</style>
