<template>
  <section>
    <div class="sinner">
      <h2 class="sec_title">{{ title }}</h2>
      <div class="swiper prod_swiper nomal_swiper">
        <swiper :options="options">
          <swiper-slide v-for="item in items" :key="item.cit_id">
            <product-item :bookMark="true" :item="item" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ProductItem from '@/components/ui/ProductItem/ProductItem.vue';

export default {
  name: 'ProductSwiperList',
  props: ['title', 'items'],
  components: { Swiper, SwiperSlide, ProductItem },
  setup() {
    return {
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
    };
  },
};
</script>

<style scoped>
.swiper-slide a {
  display: block;
  width: 100%;
}
.prod_swiper .swiper-slide {
  margin-right: 10px;
  position: relative;
  width: calc((100% / 2) - 15px);
}
.prod_swiper .swiper-slide a div {
  position: relative;
}

.prod_img button {
  bottom: 0;
  right: 0;
}
</style>
