<template>
  <div class="search_brand_con">
    <div class="disflex">
      <div class="con pro_desc_box type3">
        <router-link :to="`/brand/detail/${id}`">
          <img :src="img" class="profile_img" />
          <div class="profile_user">
            <h3 class="name">{{ name }}</h3>
            <p class="desc">{{ desc }}</p>
          </div>
        </router-link>
      </div>
      <p class="con">
        <book-mark-button
          :key="isPick"
          class="list"
          type="p"
          :pickItemId="id"
          :value="isPick"
          @update="handleClickBookMark"
        />
      </p>
    </div>
    <product-list
      :items="products"
      class="swiper prod_swiper nomal_swiper instance2-0 swiper-initialized swiper-horizontal swiper-backface-hidden"
    />
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue-demi';
import BookMarkButton from '../../components/ui/Buttons/BookMarkButton.vue';
import ProductList from '../main/ProductList.vue';
import ProductAPI from '../../apis/ProductAPI';
import usePick from '../../composables/user/usePick';
export default {
  components: { ProductList, BookMarkButton },
  props: ['id', 'name', 'desc', 'items', 'item', 'img'],
  setup(props, context) {
    const { getUserPick, isPick: isPickBrands } = usePick();
    const state = reactive({
      products: [],
    });

    const brand = ref(props.item);
    const isPick = ref(false);
    const pickBrands = ref(null);
    const loadPickBrands = async () => {
      const brands = await getUserPick('brands');
      pickBrands.value = brands;
      isPick.value = isPickBrands('brands', props.id);
    };

    loadPickBrands();

    const loadDatas = async () => {
      const { data } = await ProductAPI.getItemsByType({
        page: 0,
        limit: 10,
        den_id: props.id,
        orderBy: '',
        mem_id: 0,
      });

      state.products = [...state.products, ...data.result_data];
    };

    loadDatas();

    return {
      ...toRefs(state),
      isPick,
      brand,
      props,
      pickBrands,
      handleClickBookMark(checked) {
        const newItem = { ...brand.value };

        isPick.value = checked;

        newItem.count = Number(newItem.COUNT) + (checked ? 1 : -1);
        brand.value = newItem;
        context.emit('update:bookmark', brand.value);
      },
    };
  },
};
</script>

<style>
.search_brand_con .sinner {
  padding: 0;
}

.search_brand_con .sec_title {
  padding: 0;
}
</style>
