<template>
  <div class="wrap search-result">
    <search-header />
    <div class="layout_body search_contain allContent_contain">
      <div v-if="isLoading" class="loading_wrap">
        <div class="loading_content">
          <img src="../../assets/images/design/loading.gif" alt="loading" />
        </div>
      </div>
      <div class="all_header">
        <article class="swiper search_category">
          <swiper id="swiper-header" class="swiper-wrapper" :options="options">
            <swiper-slide id="swiper-list" class="swiper-slide on">
              <router-link :to="`/search/result?keyword=${keyword}`">
                {{ $t('Search.All') }}
                {{ allTotal.numberFormat() }}</router-link
              >
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/product?keyword=${keyword}`">
                {{ $t('Search.Product') }} {{ productsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/brand?keyword=${keyword}`">
                {{ $t('Search.Brand') }} {{ brandsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/event?keyword=${keyword}`">
                {{ $t('Search.Event') }} {{ eventsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/coupon?keyword=${keyword}`">
                {{ $t('Search.Coupon') }} {{ couponsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
          </swiper>
        </article>
      </div>

      <div v-if="!isLoading && allTotal === 0" class="alone_txt">
        <p>{{ $t('Search.NoneSearch') }}</p>
      </div>
      <div v-if="productsTotal > 0" class="inner">
        <span
          class="disblock"
          v-html="$t('Search.TotalResult', { Total: productsTotal })"
        >
        </span>
        <h3 class="new_sub_title">{{ $t('Search.Product') }}</h3>
        <div class="prod_swiper noswiper_prod p0">
          <ul>
            <li v-for="item in products" :key="item.cit_id">
              <product-item :item="item" :bookMark="true" />
            </li>
          </ul>
        </div>
        <router-link
          v-if="productsTotal > 10"
          :to="`/search/result/product?keyword=${keyword}`"
          class="default_btn"
        >
          {{ $t('Search.ALLView') }} <strong>{{ productsTotal }}</strong>
        </router-link>
      </div>
      <div v-if="brandsTotal > 0" class="inner">
        <h3 class="new_sub_title">{{ $t('Search.Brand') }}</h3>
        <search-result-brand-item
          v-for="brand of brands"
          :id="brand.den_id"
          :key="brand.den_id"
          :item="brand"
          :name="brand.den_name"
          :img="brand.img_url"
          :desc="`${brand.provider_concept1} ${brand.provider_concept2}`"
          :items="[]"
        />
        <router-link
          v-if="brandsTotal > 10"
          :to="`/search/result/brand?keyword=${keyword}`"
          class="default_btn"
        >
          {{ $t('Search.ALLView') }}
          <strong>{{ brandsTotal.numberFormat() }}</strong>
        </router-link>
      </div>
      <div v-if="eventsTotal > 0" class="inner evnet">
        <h3 class="new_sub_title">{{ $t('Search.Event') }}</h3>
        <search-result-event-item
          v-for="event of events"
          :id="event.cbe_id"
          :key="event.cbe_id"
          :title="event.cbe_title"
          :content="event.coupon_text"
          :start-date="event.start_date"
          :img="event.img"
          :end-date="event.end_date"
        />
        <router-link
          v-if="eventsTotal > 10"
          :to="`/search/result/event?keyword=${keyword}`"
          class="default_btn"
        >
          {{ $t('Search.ALLView') }} <strong>{{ eventsTotal }}</strong>
        </router-link>
      </div>
      <div v-if="couponsTotal > 0" class="inner evnet">
        <h3 class="new_sub_title">{{ $t('Search.Coupon') }}</h3>

        <search-result-coupon-list :items="coupons" />
        <router-link
          v-if="couponsTotal > 10"
          :to="`/search/result/coupon?keyword=${keyword}`"
          class="default_btn"
        >
          {{ $t('Search.ALLView') }} <strong>{{ couponsTotal }}</strong>
        </router-link>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import { computed, reactive, toRefs } from 'vue-demi';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import SearchHeader from '../../components/layouts/components/headers/SearchHeader.vue';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import SearchResultBrandItem from './SearchResultBrandItem.vue';
import SearchResultCouponList from './SearchResultCouponList.vue';
import SearchAPI from '../../apis/SearchAPI';
import EventAPI from '../../apis/EventAPI';
import SearchResultEventItem from './SearchResultEventItem.vue';

export default {
  components: {
    Swiper,
    SwiperSlide,
    SearchHeader,
    SildFooter,
    ProductItem,
    SearchResultBrandItem,
    SearchResultCouponList,
    SearchResultEventItem,
  },
  setup(_, context) {
    const query = context.root.$route.query;
    const keyword = query?.keyword;

    if (keyword) {
      const keywords = JSON.parse(localStorage.getItem('keywords') ?? '[]');

      const idx = keywords.indexOf(query.keyword);

      if (idx > -1) keywords.splice(idx, 1);

      localStorage.setItem(
        'keywords',
        JSON.stringify([query.keyword, ...keywords])
      );
    }

    const state = reactive({
      isLoading: false,
      products: [],
      productsTotal: 0,
      brands: [],
      brandsTotal: 0,
      coupons: [],
      couponsTotal: 0,
      events: [],
      eventsTotal: 0,
    });

    const allTotal = computed(() => {
      return (
        state.productsTotal +
        state.brandsTotal +
        state.couponsTotal +
        state.eventsTotal
      );
    });

    const loadsearchKeyword = async () => {
      state.isLoading = true;
      const { data: productResult } = await SearchAPI.searchItem({
        keyword,
        page: 0,
        limit: 10,
      });
      const { data: eventData } = await EventAPI.getEventList({
        offset: 0,
        order_by: 'start',
        mem_id: 1,
        is_option: '',
      });
      const { data } = await SearchAPI.searchKeyword(keyword);
      const [brandResult, couponResult] = data.body.result;

      state.products = [
        ...state.products,
        ...productResult.body.result.result_data,
      ];
      state.productsTotal = Number(productResult.body.result.count);
      state.brands = brandResult.result_data.map((n) => ({
        ...n,
        img_url: `${process.env.VUE_APP_FILE_URL}${n.img_url}`,
      }));
      state.brandsTotal = Number(brandResult.count);
      state.coupons = couponResult.result_data;
      state.couponsTotal = Number(couponResult.count);
      state.events = eventData.list.map((n) => ({
        ...n,
        img: `${process.env.VUE_APP_FILE_URL}${n.main_img}`,
      }));
      state.eventsTotal = Number(eventData.total_rows);
      state.isLoading = false;
    };

    loadsearchKeyword();

    return {
      ...toRefs(state),
      keyword,
      allTotal,
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
    };
  },
};
</script>

<style>
#swiper-header {
  overflow: auto;
  white-space: nowrap;
}

#swiper-list {
  display: inline-block;
}

.search-result .image-bg-src {
  height: auto;
}

.search-result .apply_pro {
  width: 60px;
}

.search-result .coupon_content > p {
  justify-self: flex-end;
  margin-top: auto;
}

.search-result .coupon_content > p span:first-child {
  font-weight: bold;
  font-size: 16px;
}

.coupon_content h4,
.coupon_content > p span {
  font-size: 12px;
}
.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 999;
}
.loading_content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50em;
}
@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.loading_content {
  z-index: 99;
  background: #fff;
}
</style>
