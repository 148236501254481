<template>
  <!--최근검색어-->
  <div>
    <section class="recently_list">
      <div class="disflex">
        <h2 class="new_sub_title">{{ $t('Search.Recently') }}</h2>
        <button
          type="button"
          class="btn_pop_open three_dot"
          @click="isShowOption = true"
        />
      </div>
      <div v-if="!useRecentKeywords" class="no-item">
        {{ $t('Search.SaveOff') }}
      </div>
      <div v-else-if="keywords.length === 0" class="no-item">
        {{ $t('Search.NoneRecently') }}
      </div>
      <ul v-else class="search_ranking disflex">
        <li
          v-for="(val, idx) in keywords"
          :key="idx"
          @click="handleClickKeyword(val)"
        >
          {{ val }}
        </li>
      </ul>
    </section>
    <keyword-options-popup
      v-if="isShowOption"
      @close="isShowOption = false"
      @confirm="handleChangeAutoSearch"
    />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';
import KeywordOptionsPopup from './popups/KeywordOptionsPopup.vue';

export default {
  components: { KeywordOptionsPopup },
  setup(_, context) {
    const state = reactive({
      isShowOption: false,
      isShow: false,
      keywords: [],
      useRecentKeywords: false,
    });

    const setAutoSearchInfo = () => {
      const recent = localStorage.getItem('recent-keywords');

      state.useRecentKeywords = recent === undefined || recent === 'on';

      state.keywords = JSON.parse(localStorage.getItem('keywords') ?? '[]');

      console.log(state.keywords);

      state.isShow = state.keywords.length > 0 && state.useRecentKeywords;
    };

    setAutoSearchInfo();

    return {
      ...toRefs(state),

      handleChangeAutoSearch() {
        state.isShowOption = false;
        setAutoSearchInfo();
      },

      handleClickKeyword(val) {
        context.root.$router.push({
          path: '/search/result',
          query: {
            keyword: val,
          },
        });
      },
    };
  },
};
</script>

<style>
.recently_list .no-item {
  text-align: center;
  margin-top: 25px;
}
</style>
