<template>
  <router-link :to="`/event/${id}`">
    <div class="event_img">
      <img :src="`${img}`" />
    </div>
    <div class="event_caption">
      <h4 class="event_tit">{{ title }}</h4>
      <p class="event_sub">
        <span>{{ content }}</span
        ><span>{{ startDate }} ~ {{ endDate }}</span>
      </p>
    </div>
  </router-link>
</template>

<script>
export default {
  props: ['id', 'title', 'content', 'startDate', 'endDate', 'img'],

  setup() {
    return {};
  },
};
</script>

<style></style>
