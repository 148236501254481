<template>
  <div class="wrap search-result">
    <search-header />
    <div class="layout_body search_contain allContent_contain">
      <div class="all_header">
        <article class="swiper search_category">
          <swiper id="swiper-header" class="swiper-wrapper" :options="options">
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result?keyword=${keyword}`">
                {{ $t('Search.All') }}
                {{ allTotal.numberFormat() }}</router-link
              >
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/product?keyword=${keyword}`">
                {{ $t('Search.Product') }} {{ productsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/brand?keyword=${keyword}`">
                {{ $t('Search.Brand') }} {{ brandsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide">
              <router-link :to="`/search/result/event?keyword=${keyword}`">
                {{ $t('Search.Event') }} {{ eventsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
            <swiper-slide id="swiper-list" class="swiper-slide on">
              <router-link :to="`/search/result/coupon?keyword=${keyword}`">
                {{ $t('Search.Coupon') }} {{ couponsTotal.numberFormat() }}
              </router-link>
            </swiper-slide>
          </swiper>
        </article>
      </div>
      <div :class="`${isLoading ? '' : 'off'} inner`">
        <span
          class="disblock"
          v-html="$t('Search.TotalResult', { Total: couponsTotal })"
        >
        </span>
        <h3 class="new_sub_title">{{ $t('Search.Coupon') }}</h3>

        <coupon-item
          v-for="item in items"
          :key="item.ccp_id"
          :item="item"
          :isOn="item.download_count == 0"
          @download="item.download_count++"
        />

        <infinite-loading
          v-if="hasMore"
          :identifier="infiniteId"
          style="opacity: 0"
          @infinite="loadDatas"
        />
        <div v-if="productsTotal === 0" class="empty_result_search">
          <p>{{ $t('Search.NoneSearch') }}</p>
        </div>
      </div>
      <div v-if="!isLoading" class="loading_wrap">
        <div class="loading_content">
          <img src="../../assets/images/design/loading.gif" alt="loading" />
        </div>
      </div>
    </div>
    <div>
      <sild-footer class="items-sild-footer" />
    </div>
  </div>
</template>

<script>
import { computed, reactive, ref, toRefs } from 'vue-demi';
import InfiniteLoading from 'vue-infinite-loading';
import CouponItem from '../../components/ui/CouponItem/CouponItem.vue';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import { toggleNav } from '../../utils/GlobalUtils';
import useUserInfo from '../../composables/user/useUserInfo';
import SearchHeader from '../../components/layouts/components/headers/SearchHeader.vue';
import SearchAPI from '../../apis/SearchAPI';
import EventAPI from '../../apis/EventAPI';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
  components: {
    Swiper,
    SwiperSlide,
    InfiniteLoading,
    SildFooter,
    SearchHeader,
    CouponItem,
  },
  setup(_, context) {
    const { info } = useUserInfo();
    const query = context.root.$route.query;
    const keyword = query?.keyword;
    let isScroll = ref(false);
    const state = reactive({
      type: 'w',
      hasMore: true,
      infiniteId: Date.now(),
      items: [],
      allTotal: 0,
      productsTotal: 0,
      brandsTotal: 0,
      eventsTotal: 0,
      couponsTotal: 0,
      isLoading: false,
    });

    const allTotal = computed(() => {
      return (
        state.productsTotal +
        state.brandsTotal +
        state.couponsTotal +
        state.eventsTotal
      );
    });

    const params = {
      page: 0,
      limit: 10,
      mem_id: info?.mem_id ?? 0,
    };

    let beforePosition = document.documentElement.scrollTop;
    let isScrolling;
    window.addEventListener('scroll', function () {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(function () {
        isScroll.value = false;
      }, 66);
    });
    window.addEventListener('scroll', function () {
      let afterPosition = document.documentElement.scrollTop;
      if (afterPosition > 50) {
        if (beforePosition < afterPosition) {
          // 스크롤 위로
          isScroll.value = true;
        } else {
          // 스크롤 아래로
          isScroll.value = false;
        }
      }
      beforePosition = afterPosition;
    });

    const loadDatas = async (attr) => {
      try {
        const { data } = await SearchAPI.searchKeyword(keyword);
        const [, couponResult] = data.body.result;

        state.items = [...state.items, ...couponResult.result_data];
        state.couponsTotal = Number(couponResult.count);

        if (couponResult.result_data.length !== params.limit) {
          attr?.complete();
          state.hasMore = false;
        } else {
          params.page++;
          attr?.loaded();
        }
        state.isLoading = true;
      } catch (error) {
        console.log(error);
      }
    };

    const loadAnotherSectionSearchCount = async () => {
      const { data: productResult } = await SearchAPI.searchItem({
        keyword,
        page: 0,
        limit: 10,
        mem_id: 0,
      });
      const { data: eventData } = await EventAPI.getEventList({
        offset: 0,
        order_by: 'start',
        mem_id: info?.mem_id,
        is_option: '',
      });
      const { data } = await SearchAPI.searchKeyword(keyword);
      const [brandResult] = data.body.result;

      state.productsTotal = Number(productResult.body.result.count);
      state.brandsTotal = Number(brandResult.count);
      state.eventsTotal = Number(eventData.total_rows);
    };

    loadAnotherSectionSearchCount();

    return {
      ...toRefs(state),
      allTotal,
      keyword,
      loadDatas,
      isScroll,
      sortItems,
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
      getMenuClassName(value) {
        const classes = ['menu', 'fontEn'];

        if (value === state.type) {
          classes.push('on');
        }

        return classes.join(' ');
      },
    };
  },
  created() {
    window.addEventListener('scroll', toggleNav);
  },
  destroyed() {
    window.removeEventListener('scroll', toggleNav);
  },
};

const sortItems = [
  {
    key: '',
    label: '추천순',
  },
  {
    key: 'new_dtm',
    label: '신상품순',
  },
  {
    key: 'upd_dtm',
    label: '업데이트순',
  },
  {
    key: 'cit_sale_price_desc',
    label: '가격높은순',
  },
  {
    key: 'cit_sale_price_asc',
    label: '가격낮은순',
  },
  {
    key: 'sale',
    label: '할인율순',
  },
];
</script>

<style>
.btn_box.filter-box {
  bottom: 74px;
}
.btn_box.filter-box.nav-down {
  bottom: 74px;
}
.btn_box.filter-box.nav-up {
  bottom: -74px;
}
.filter-box.slid_up {
  top: auto !important;
  transition: bottom 0.2s ease-in-out !important;
}
.off {
  opacity: 0;
}
.main .layout_body {
  padding-top: 0;
}

.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 999;
}
.loading_content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50em;
}
@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .loading_content .timesale_wrap {
    width: 50%;
  }
}
</style>
