<template>
  <header class="layout_header new_page_header">
    <div class="hinner disflex">
      <a class="back absolute" @click="$router.back()"></a>
      <a class="serch mr10" @click="handleSearch"></a>
      <div style="width: 100%">
        <input
          :value="keyword"
          class="all_search"
          type="search"
          :placeholder="$t('Layout.Search')"
          @input="changeKeyword"
          @keydown="handleKeydown"
        />
      </div>
    </div>
  </header>
</template>

<script>
import { ref } from 'vue-demi';
export default {
  setup(_, context) {
    const keyword = ref(context.root.$route.query.keyword);
    return {
      keyword,
      changeKeyword(e) {
        this.keyword = e.target.value;
      },
      handleSearch() {
        window.location = '/search/result?keyword=' + keyword.value + '';
        // context.root.$router.push({
        //   path: "/search/result",
        //   query: {
        //     keyword: keyword.value,
        //   },
        // });
      },
      handleKeydown(e) {
        console.log(e);
        if (e.key === 'Enter') {
          this.handleSearch();
        }
      },
    };
  },
};
</script>

<style></style>
