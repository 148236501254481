<template>
  <swiper class="coupon_swiper" :options="options">
    <swiper-slide v-for="coupon of items" :key="coupon.ccp_id">
      <coupon-item
        :key="coupon.ccp_id"
        :item="coupon"
        :isOn="coupon.download_count == 0"
        @download="coupon.download_count++"
      />
    </swiper-slide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import CouponItem from '../../components/ui/CouponItem/CouponItem.vue';

export default {
  props: {
    items: {
      default: [],
    },
  },
  components: { Swiper, SwiperSlide, CouponItem },

  setup() {
    return {
      options: {
        slidesPerView: 'auto',
        watchOverflow: true,
      },
    };
  },
};
</script>

<style></style>
