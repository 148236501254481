<template>
  <sort-popup
    :title="$t('Search.KeyWord')"
    :items="items"
    @close="$emit('close')"
    @select="handleSelectItem"
  />
</template>

<script>
import { ref } from 'vue-demi';
import SortPopup from '../../../components/popups/SortPopup.vue';
export default {
  components: { SortPopup },
  setup(_, context) {
    const items = ref(
      localStorage.getItem('recent-keywords') === 'off'
        ? [
            {
              key: 'clear',
              label: context.root.$t('Search.Delete'),
            },
            {
              key: 'on',
              label: context.root.$t('Search.AutoOn'),
            },
          ]
        : [
            {
              key: 'clear',
              label: context.root.$t('Search.Delete'),
            },
            {
              key: 'off',
              label: context.root.$t('Search.AutoOff'),
            },
          ],
    );

    return {
      items,
      handleSelectItem(item) {
        switch (item.key) {
          case 'clear':
            localStorage.setItem('keywords', '[]');
            break;
          case 'off':
            localStorage.setItem('recent-keywords', 'off');
            break;
          case 'on':
            localStorage.setItem('recent-keywords', 'on');
            break;
          default:
            break;
        }

        context.emit('confirm', item);
      },
    };
  },
};
</script>

<style></style>
