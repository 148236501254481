<template>
  <div class="wrap">
    <search-header />
    <div class="layout_body">
      <div class="inner">
        <search-keywords />
        <!--인기검색어-->
        <section class="ranking_list_wrap popular">
          <h2 class="new_sub_title">{{ $t('Search.PopularSearch') }}</h2>
          <ul
            v-for="(item, idx) in keywordRanking"
            :key="idx"
            class="search_ranking"
          >
            <li
              class="disflex"
              @click="
                $router.push({
                  path: '/search/result',
                  query: {
                    keyword: item.keyword,
                  },
                })
              "
            >
              <!-- css로 순서 숫자 추가 -->
              <p class="con num"></p>
              <div class="con pro_desc_box type3">
                <p>{{ item.keyword }}</p>
              </div>
              <!-- .state 부분에 .up 빨강아이콘 / .down 파랑아이콘 추가 -->
              <p v-if="item.new" class="con state">
                <span>
                  <img src="../../assets/images/design/NEW.svg" alt="" />
                </span>
              </p>
              <p
                v-else
                class="con state"
                style="width: 30px; text-align: center"
              >
                <span v-if="item.rank_change > 0">
                  <img
                    src="../../assets/images/design/ranking-up.svg"
                    alt=""
                  />{{ item.rank_change }}
                </span>
                <span v-else-if="item.rank_change < 0">
                  <img
                    src="../../assets/images/design/ranking-down.svg"
                    alt=""
                  />{{ item.rank_change }}
                </span>
                <span v-else-if="item.rank_change === 0">-</span>
              </p>
            </li>
          </ul>
        </section>
      </div>
    </div>
    <sild-footer />
  </div>
</template>

<script>
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import SearchHeader from '../../components/layouts/components/headers/SearchHeader.vue';
import SearchKeywords from './SearchKeywords.vue';
import SearchAPI from '../../apis/SearchAPI';
import { reactive, toRefs } from 'vue-demi';
export default {
  components: { SearchHeader, SearchKeywords, SildFooter },
  setup() {
    const state = reactive({
      keywordRanking: [],
    });

    const loadKeywordRanking = async () => {
      const { data } = await SearchAPI.searchKeywordRanking();
      state.keywordRanking = data.body.result.ranking;
    };
    loadKeywordRanking();
    return {
      ...toRefs(state),
    };
  },
};
</script>

<style></style>
